import { memo } from "react";

const ProgresBar = memo((props) => {
    return (
        <div className="progress_panel">
            <div className="progress_text">
                <span className="league">League {Math.floor(props.data.balance / 10000) + 1}</span>
                <span className="level">Level</span>
            </div>
            <div className="pr_lay">
                <progress id="file" max="100" value={Math.floor(props.data.balance % 10000)/ 100 }></progress>
            </div>
            
        </div>
        

)});

export default ProgresBar;